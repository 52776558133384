import * as React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeCatFeedingMats from '../../../../components/helper_size_cat_feeding_mats'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Validate from '../../../../components/validation.js';
import FieldText from '../../../../components/field_text.js';
import FieldErrorMessage from '../../../../components/field_error_message';
import Breadcrumb from "../../../../components/breadcrumb"

const CatsFeedingMatsDouble = ({data}) => {
  
  //console.log('data: ', data)
  const entry = data.prismicCatsFeedingMatsDouble

  const {getProductById} = useContext(CartContext);
  //console.log('getProductById: ', getProductById)

  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);

  const [selectedVariantColour, setSelectedVariantColour] = useState(data.shopifyProduct.options[0].values[0])
  
  let custom_colour_combination = getStockLevels(entry.data.custom_colour_combination, 'custom_colour_combination');
  const [selectedColour, setSelectedColour] = useState(custom_colour_combination[0].name)
  const [selectedColourImage, setSelectedColourImage] = useState(custom_colour_combination[0])
  
  const [selectedGraphic, setSelectedGraphic] = useState(entry.data.custom_graphic[0].name)
  const [selectedGraphicImage, setSelectedGraphicImage] = useState(entry.data.custom_graphic[0])
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: false,
    formControls: {
      custom_text: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired:  true,
            maxLength: '12'
        },
        errorMessages: ''
      }
    }
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Colour",
      value: selectedColour
    },
    {
      key: "Text",
      value: formValues.formControls.custom_text.value
    },
    {
      key: "Graphic",
      value: selectedGraphic
    },
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.storefrontId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.storefrontId,
    variantId,
    selectedColour,
    selectedColourImage,
    selectedGraphic,
    selectedGraphicImage,
    customSelections
  ]);

  function getStockLevels(data, component) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  const handleVariantColourChange = e => {
    setSelectedVariantColour(e.target.value);
    let selectedVariantColourChange = `${e.target.value}`

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantColourChange
    )
    setVariantId(newVariant.shopifyId);

    setSelectedColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedColourImage = entry.data.custom_colour_combination.find(image => (
      image.name === e.target.value
    ))
    setSelectedColourImage(newSelectedColourImage)
  };

  const handleGraphicChange = e => {
    setSelectedGraphic(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Graphic') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const changeFormHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });

    const newCustomSelections = customSelections.map(customSelection => {
      if (name === 'custom_text' && customSelection.key === 'Text') {
        return {...customSelection, value: value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  }

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Cats" 
        category_url="cats"
        subcategory="Feeding Mats" 
        subcategory_url="feeding-mats"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose colour combination</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <div className="mb-2">
                    <HelperSizeCatFeedingMats name="Size guide" data={data} />
                  </div>
                  <Helper name="Acrylic" data={entry.data.feeding_mat_materials} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose colour combination</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-6 text-left md:text-center">
                      {custom_colour_combination.map((custom,i) => (
                        <label htmlFor={`custom_colour_${custom.name}`} key={i} className={custom.name === selectedColour ? 'text-sm md:text-base flex items-center px-3 py-3 md:px-6 md:py-3 rounded-xl border-2 border-diamond cursor-pointer' : 'text-sm md:text-base flex items-center px-3 py-3 md:px-6 md:py-3 border-2 border-transparent cursor-pointer'}>
                          <input
                            id={`custom_colour_${custom.name}`}
                            defaultChecked={custom.name === selectedColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleVariantColourChange}
                            name="colour"
                            className="hidden"
                          />
                          <div>
                            <p className="font-poppins font-medium text-diamond-80 leading-tight mb-6">{custom.name}</p>
                            <div className="flex justify-center">
                              <div style={{backgroundColor: `${custom.colour}`}} className="w-8 md:w-16 h-8"></div>
                              <div style={{backgroundColor: `${custom.colour2}`}} className="w-8 md:w-16 h-8"></div>
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedColourImage.image.gatsbyImageData} alt={selectedColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/personalisation_white.png" className="w-28 h-28" alt="Personalise name plate" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Personalise</h2>
              </div>
              {!!selectedVariant && 
                <div className="">
                  <div className="sr-only">Personalisation</div>
                  <div className="w-full md:w-9/12">

                    <div className="gap-6">
                      <label htmlFor="custom_text" className="text-sm md:text-base font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text <small className="font-italic text-sm">(1 to 2 words)</small></label>
                      <FieldText
                        id="custom_text"
                        name="custom_text"
                        type={formValues.formControls.custom_text.type}
                        placeholder={formValues.formControls.custom_text.placeholder}
                        value={formValues.formControls.custom_text.value}
                        onChange={changeFormHandler}
                        className={formValues.formControls.custom_text.touched && !formValues.formControls.custom_text.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                      />
                      <FieldErrorMessage
                        className={formValues.formControls.custom_text.touched && !formValues.formControls.custom_text.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                        errorMessages={formValues.formControls.custom_text.errorMessages}
                      />
                    </div>

                    <div className="pt-12">
                      <div className="flex items-center justify-between pb-6">
                        <h2 className="font-poppins text-2xl font-semibold text-diamond">Choose your graphic</h2>
                      </div>
                      <div className="grid grid-cols-3 sm:grid-cols-5 md:grid-cols-6 gap-3 text-left md:text-center">
                        {entry.data.custom_graphic.map((custom, i) => (
                          <label htmlFor={`custom_graphic_${custom.name}`} key={i} className={custom.name === selectedGraphic ? 'flex items-center px-6 py-3 rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center px-6 py-3 border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`custom_graphic_${custom.name}`}
                              defaultChecked={custom.name === selectedGraphic}
                              type="radio"
                              value={custom.name}
                              onChange={handleGraphicChange}
                              name="custom_graphic"
                              className="hidden"
                            />
                            <GatsbyImage className="object-contain block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                  </div>
                  <div className="w-full w-3/12">
                    &nbsp;
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>
              
              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideCatFeedingMats {
    data {
      heading
      text {
        richText
      }
      table_1 {
        col_1
        col_2
        col_3
      }
    }
  }
  prismicCatsFeedingMatsDouble {
    data {
      out_of_stock {
        component
        name
      }
      page_title
      meta_description
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      feeding_mat_materials {
        richText
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      custom_colour_combination {
        name
        colour
        colour2
        image {
          alt
          gatsbyImageData
        }
      }
      custom_graphic {
        name
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "cats-feeding-mats-double"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      id
      name
      values
    }
  }
}
`

export default CatsFeedingMatsDouble
